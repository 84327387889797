import React from "react";
import { useLocation } from "react-router-dom";
import Alert from "@mui/material/Alert";
import { useEffect, useState } from "react";
import { IconButton } from "@mui/material";

const Dashboard: React.FC = () => {
  const [Message, setMessage] = useState<string>("");
  const token = localStorage.getItem("authtoken");
  const [showAlert, setShowAlert] = useState(true); 

  useEffect(() => {
    const storedMessage = sessionStorage.getItem("message");
    if (storedMessage) {
      setMessage(storedMessage);
      sessionStorage.removeItem("message");
    }
  }, []);

  useEffect(() => {
    const storedMessages = sessionStorage.getItem("signup");
    if (storedMessages) {
      setMessage(storedMessages);
      sessionStorage.removeItem("signup");
    }
  }, []);

  return (
    <div>
      {Message && showAlert && <Alert action={
      <IconButton
        aria-label="close"
        color="inherit"
        onClick={() => setShowAlert(false)}
      ></IconButton>} style={{ color: "#3c763d" }}>{Message}</Alert>}

      <div
        style={{
          display: "flex",
          marginLeft: "10px",
          marginTop: "5px",
          backgroundColor: "#f3f3f4",
        }}
      >
        <iframe
          id="cotgdash"
          width="100%"
          height={720}
          src={`https://analytics.texthub.com/public/dashboard/2bffa899-962b-4320-a8c6-2ab5025c32fa?token=${token}`}
          frameBorder={0}
        ></iframe>
      </div>
    </div>
  );
};

export default Dashboard;
