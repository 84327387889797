import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Paper,
  Typography,
  Button,
  Link,
  IconButton,
} from "@mui/material";
import { TEXT, BUTTON, CARDURL, URL } from "../constant";
import PheonixTextField from "../Components/PheonixTextField";
import SubmitButton from "../Components/PheonixButton";
import main from "../assets/CALLontheGO_icon_.png";
import link from "../assets/integration_line_logo.png";
import contact from "../assets/contacts.png";
import on from "../assets/on.png";
import ads from "../assets/ads.png";
import calender from "../assets/calculator.png";
import hub from "../assets/hub.png";
import mail from "../assets/mail.png";
import PheonixWizardModal from "../Components/PheonixWizardModel";
import { saveAs } from "file-saver";
import axios from "axios";
import { config } from "../config";
import { api } from "../api";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";

interface Contact {
  first_name: string;
  last_name: string;
  number: string;
  email: string;
  notes: string;
}

const Settings: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showapi, setshowapi] = useState(false);
  const authtoken = localStorage.getItem("authtoken");
  const token = localStorage.getItem("authtoken");
  const [ShowAlert, setShowAlert] = useState(false);
  const [currentPasswordError, setCurrentPasswordError] = useState(false);
  const [currentPasswordHelperText, setCurrentPasswordHelperText] =
    useState("");
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [passwordMatchHelperText, setPasswordMatchHelperText] = useState("");
  const [passwordLengthError, setPasswordLengthError] = useState(false);
  const [passwordLengthHelperText, setPasswordLengthHelperText] = useState("");
  const [loginemail, setLoginEmail] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    const storedEmail = sessionStorage.getItem("email");
    const storedLoginEmail = sessionStorage.getItem("loginemail");

    if (storedEmail) {
      setEmail(storedEmail);
    }

    if (storedLoginEmail === "true") {
      setLoginEmail(true);
    } else {
      setLoginEmail(false);
    }
  }, []);

  const [contacts, setContacts] = useState<Contact[]>([]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleModelopen = () => {
    setIsModalOpen(true);
  };
  const handleshowapi = () => {
    setshowapi(!showapi);
  };

  const handlegetContact = () => {
    axios
      .get(
        config.baseUrl + "/v1/contacts",

        {
          headers: {
            "x-cotg-authtoken": token,
          },
        }
      )
      .then((response) => {
        setContacts(response.data.contacts);
      })
      .catch((error) => {
        console.error("MODALTEXT.ERROR", error);
      });
  };
  const handleexport = () => {
    const header = ["first_name", "last_name", "number", "email", "notes"];
    const data = [
      header,
      ...contacts.map((contact) => [
        contact.first_name,
        contact.last_name,
        contact.number,
        contact.email,
        `"${contact.notes || ""}"`,
      ]),
    ];

    const csvContent = data.map((row) => row.join(",")).join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    saveAs(blob, "user-list.csv");
  };

  const handleGetAndExport = async () => {
    await handlegetContact();
    handleexport();
  };
  const handleCheckPassword = async () => {
    const query = `mutation CheckPassword($email: String!, $currentPassword: String!) {
        checkPassword(
            email: $email,
            currentPassword: $currentPassword
        ) {
            success,
            message
        }
    }`;

    const variables = {
      email: email,
      currentPassword: currentPassword,
    };

    try {
      const response = await axios.post(
        api.baseUrl,
        {
          query,
          variables,
        },
        {
          headers: {
            "x-cotg-authtoken": token,
          },
        }
      );
      const { success, message } = response.data.data.checkPassword;
      if (!success) {
        setCurrentPasswordError(true);
        setCurrentPasswordHelperText("is invalid");
      } else {
        setCurrentPasswordError(false);
        setCurrentPasswordHelperText("");
      }
    } catch (error) {
      console.error("Failed to check password:", error);
    }
  };

  const handleUpdatePassword = async () => {
    if (password.length < 8) {
      setPasswordLengthError(true);
      setPasswordLengthHelperText("is too short (minimum is 8 characters)");
      return;
    }

    if (password !== passwordConfirmation) {
      setPasswordMatchError(true);
      setPasswordMatchHelperText("Passwords do not match");
      return;
    }

    const query = `mutation UpdatePassword($email: String!, $currentPassword: String!, $password: String!, $passwordConfirmation: String!) {
      updatePassword(
          email: $email,
          currentPassword: $currentPassword,
          password: $password,
          passwordConfirmation: $passwordConfirmation
      ) {
          success,
          message
      }
  }`;

    const variables = {
      email: email,
      currentPassword: currentPassword,
      password: password,
      passwordConfirmation: passwordConfirmation,
    };

    try {
      const response = await axios.post(
        api.baseUrl,
        {
          query,
          variables,
        },
        {
          headers: {
            "x-cotg-authtoken": token,
          },
        }
      );

      const { success, message } = response.data.data.updatePassword;
      if (success) {
        setShowAlert(true);
        setMessage("Password updated successfully");
        setTimeout(() => {
          setShowAlert(false);
          setMessage("");
        }, 5000);
        setCurrentPassword("");
        setPassword("");
        setPasswordConfirmation("");
        setPasswordMatchError(false);
        setPasswordMatchHelperText("");
      } else {
        setPasswordMatchError(true);
        setPasswordMatchHelperText("doesn't match Password");
      }
    } catch (error) {
      console.error("Failed to update password:", error);
    }
  };

  const handlePasswordUpdateFlow = async () => {
    await handleCheckPassword();
    await handleUpdatePassword();
  };

  const cardData = [
    {
      title: "Add new CallOnTheGo call lists as new CRM events",
      description: "CRM Connector + CallOnTheGo",
      cardUrl: CARDURL.FIRST_URL,
      imageUrl1: link,
      imageUrl2: main,
    },
    {
      title: "Add new Google contacts as new CallOnTheGo contacts",
      description: "CallOnTheGo + Google Contacts",
      cardUrl: CARDURL.SECOND_URL,
      imageUrl1: main,
      imageUrl2: contact,
    },
    {
      title:
        "Add new Gmail emails to new CallOnTheGo lists [Business Gmail Accounts Only]",
      description: "CallOnTheGo + Gmail",
      cardUrl: CARDURL.THIRD_URL,
      imageUrl1: main,
      imageUrl2: mail,
    },
    {
      title:
        "Add new or updated Google Calendar events to new CallOnTheGo lists",
      description: "CallOnTheGo + Google Calendar",
      cardUrl: CARDURL.FOURTH_URL,
      imageUrl1: main,
      imageUrl2: calender,
    },
    {
      title: "Add new CallOnTheGo contacts for lists as HubSpot CRM contacts",
      description: "CallOnTheGo + HubSpot",
      cardUrl: CARDURL.FIFTH_URL,
      imageUrl1: main,
      imageUrl2: hub,
    },
    {
      title: "Add new Ontraport contacts as CallOnTheGo contacts",
      description: "CallOnTheGo + Ontraport",
      cardUrl: CARDURL.SIXTH_URL,
      imageUrl1: main,
      imageUrl2: on,
    },
    {
      title: "Add new CallOnTheGo contacts as new Ontraport contacts",
      description: "Ontraport + CallOnTheGo",
      cardUrl: CARDURL.SEVENTH_URL,
      imageUrl1: on,
      imageUrl2: main,
    },
    {
      title: "Add new HubSpot contacts as new CallOnTheGo contacts",
      description: "CallOnTheGo + HubSpot",
      cardUrl: CARDURL.EIGHT_URL,
      imageUrl1: main,
      imageUrl2: hub,
    },
    {
      title: "Add new CallOnTheGo contacts as new or updated HubSpot contacts",
      description: "CallOnTheGo + HubSpot",
      cardUrl: CARDURL.NINE_URL,
      imageUrl1: main,
      imageUrl2: hub,
    },
    {
      title: "Create Contact on CallOnTheGo for new Google Ads Leads ",
      description: "CallOnTheGo + Google Ads",
      cardUrl: CARDURL.TENTH_URL,
      imageUrl1: main,
      imageUrl2: ads,
    },
  ];

  const renderCards = (data: any) => {
    return data.map((card: any, index: any) => (
      <a
        key={index}
        href={card.cardUrl}
        style={{ textDecoration: "none" }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Box
          display="flex"
          alignItems="center"
          padding="10px"
          boxShadow={1}
          marginBottom="30px"
          border="1px solid #999c9e"
          borderRadius="4px"
          sx={{
            "&:hover": {
              borderColor: "#3d4592",
            },
          }}
        >
          <Box display="flex" alignItems="center" marginRight="10px" style={{}}>
            <img
              src={card.imageUrl1}
              alt={`${card.title} icon 1`}
              style={{
                marginRight: "5px",
                width: "24px",
                height: "24px",
                border: "1px solid #f3f3f4",
                padding: "2px",
                borderRadius: "4px",
              }}
            />
            <img
              src={card.imageUrl2}
              alt={`${card.title} icon 2`}
              style={{
                width: "24px",
                height: "24px",
                border: "1px solid #f3f3f4",
                padding: "2px",
                borderRadius: "4px",
              }}
            />
          </Box>
          <Box flex="1">
            <Typography
              variant="h6"
              color="#000000"
              fontSize={"14px"}
              fontWeight={"bold"}
            >
              {card.title}
            </Typography>
            <Typography variant="body2" color="#000000" fontSize={"14px"}>
              {card.description}
            </Typography>
          </Box>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#000000",
              width: "20%",
              textTransform: "none",
              fontWeight: "bold",
            }}
          >
            {TEXT.Use_Zap}
          </Button>
        </Box>
      </a>
    ));
  };
  return (
    <Box width="100%" bgcolor="#f3f3f4">
      {message && ShowAlert &&(
        <Alert
          action={ 
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={() => setShowAlert(false)}
            >
              <CloseIcon sx={{ fontSize: "small", color: "#676a6c" }} />
            </IconButton>
          }
          style={{ color: "#3c763d" }}
        >
          {message}
        </Alert>
      )}
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        padding="20px"
        justifyContent="space-between"
      >
        <Box
          display="flex"
          flexDirection="column"
          flex="1"
          marginRight={{ md: "20px" }}
        >
          <Paper
            style={{
              width: "120%",
              height: loginemail ? "370px" : "170px",
              marginTop: "20px",
              fontFamily: "sans-serif",
              color: "#676a6c",
              fontSize: "13px",
              padding: "10px",
            }}
          >
            {TEXT.MY_PROFILE}
            <Divider style={{ marginBottom: "1.5rem", marginTop: "0.5rem" }} />
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                margin="10px"
              >
                <div style={{ marginBottom: "10px", fontWeight: "bold" }}>
                  * Email{" "}
                </div>
                <PheonixTextField
                  id="outlined-error"
                  type="text"
                  value={email}
                  variant="outlined"
                  style={{
                    width: "350px",
                    height: "35px",
                    marginLeft: "20px",
                  }}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled
                />
              </Box>
              {loginemail && (
                <>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    margin="10px"
                  >
                    <div
                      style={{
                        marginLeft: "-70px",
                        marginBottom: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      Current password{" "}
                    </div>
                    <PheonixTextField
                      id="outlined-error"
                      type="password"
                      value={currentPassword}
                      variant="outlined"
                      style={{
                        width: "350px",
                        height: "35px",
                        marginLeft: "20px",
                      }}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                      error={currentPasswordError}
                      helperText={currentPasswordHelperText}
                    />
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    margin="10px"
                  >
                    <div
                      style={{
                        marginLeft: "-20px",
                        marginBottom: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      {TEXT.Password}{" "}
                    </div>
                    <PheonixTextField
                      id="outlined-error"
                      type="password"
                      value={password}
                      variant="outlined"
                      style={{
                        width: "350px",
                        height: "35px",
                        marginLeft: "20px",
                      }}
                      onChange={(e) => setPassword(e.target.value)}
                      error={passwordLengthError}
                      helperText={passwordLengthHelperText}
                    />
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    margin="10px"
                  >
                    <div
                      style={{
                        marginLeft: "-100px",
                        marginBottom: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      {TEXT.Password_confirmation}{" "}
                    </div>
                    <PheonixTextField
                      id="outlined-error"
                      type="password"
                      value={passwordConfirmation}
                      variant="outlined"
                      style={{
                        width: "350px",
                        height: "35px",
                        marginLeft: "20px",
                      }}
                      onChange={(e) => setPasswordConfirmation(e.target.value)}
                      error={passwordMatchError}
                      helperText={passwordMatchHelperText}
                    />
                  </Box>
                </>
              )}
              <SubmitButton
                label={"Update"}
                onClick={handlePasswordUpdateFlow}
                style={{
                  width: "200px",
                  marginTop: "20px",
                  marginBottom: "40px",
                  textTransform: "none",
                  color: "#FFFFFF",
                  backgroundColor: "#1ab394",
                  alignItems: "center",
                }}
              />
            </Box>
          </Paper>
          <Paper
            style={{
              marginTop: "40px",
              width: "120%",
              height: "auto",
              fontFamily: "sans-serif",
              color: "#676a6c",
              fontSize: "13px",
              padding: "10px",
            }}
          >
            {TEXT.INTEGRATIONS}
            <Divider style={{ marginBottom: "1.5rem", marginTop: "0.5rem" }} />
            {renderCards(cardData)}
            <Typography
              variant="body1"
              color="#000000"
              style={{ padding: "0px 0px 20px 0px ", textAlign: "center" }}
            >
              {TEXT.NOT_SEEING} <br />
              <a
                href={URL.CREATE_FROM_SCRATCH_URL}
                style={{ color: "#3d4592" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {TEXT.Create_from_scratch}
              </a>{" "}
              or{" "}
              <a
                href={URL.LEARN_MORE_URL}
                style={{ color: "#3d4592" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {TEXT.learn_more}
              </a>
              .
            </Typography>
          </Paper>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          flex="1"
          justifyContent="flex-start"
        >
          <Paper
            style={{
              width: "70%",
              height: !showapi ? "100px" : "130px",
              marginBottom: "20px",
              fontFamily: "sans-serif",
              marginLeft: "150px",
              color: "#676a6c",
              marginTop: "20px",
              fontSize: "13px",
              padding: "10px",
            }}
          >
            {TEXT.API}
            <Divider style={{ marginBottom: "1.5rem", marginTop: "0.5rem" }} />
            <Box display="flex" flexDirection="column" alignItems="center">
              <SubmitButton
                label={!showapi ? "Show API Token" : "Hide API Token"}
                onClick={handleshowapi}
                style={{
                  width: "400px",
                  textTransform: "none",
                  color: "#FFFFFF",
                  backgroundColor: "#1ab394",
                  alignItems: "center",
                }}
              />
            </Box>
            {showapi && (
              <Typography
                variant="body1"
                color="#676a6c"
                style={{ padding: "10px 10px 20px 20px " }}
              >
                {authtoken}
              </Typography>
            )}
          </Paper>
          <Paper
            style={{
              width: "70%",
              height: "100px",
              marginBottom: "20px",
              fontFamily: "sans-serif",
              marginLeft: "150px",
              color: "#676a6c",
              marginTop: "40px",
              fontSize: "13px",
              padding: "10px",
            }}
          >
            {TEXT.ACCOUNT}
            <Divider style={{ marginBottom: "1.5rem", marginTop: "0.5rem" }} />
            <Box display="flex" flexDirection="column" alignItems="center">
              <SubmitButton
                label={"Cancel My Account"}
                style={{
                  width: "400px",
                  textTransform: "none",
                  color: "#FFFFFF",
                  backgroundColor: "#ed5565",
                  alignItems: "center",
                }}
              />
            </Box>
          </Paper>
          <Paper
            style={{
              width: "70%",
              height: "100px",
              marginBottom: "20px",
              fontFamily: "sans-serif",
              marginLeft: "150px",
              color: "#676a6c",
              marginTop: "40px",
              fontSize: "13px",
              padding: "10px",
            }}
          >
            {TEXT.EXPORT}
            <Divider style={{ marginBottom: "1.5rem", marginTop: "0.5rem" }} />
            <Box display="flex" flexDirection="column" alignItems="center">
              <SubmitButton
                label={"Export"}
                onClick={handleGetAndExport}
                style={{
                  width: "400px",
                  textTransform: "none",
                  color: "#FFFFFF",
                  backgroundColor: "#1ab394",
                  alignItems: "center",
                }}
              />
            </Box>
          </Paper>
          <Paper
            style={{
              width: "70%",
              height: "100px",
              marginBottom: "20px",
              fontFamily: "sans-serif",
              marginLeft: "150px",
              color: "#676a6c",
              marginTop: "40px",
              fontSize: "13px",
              padding: "10px",
            }}
          >
            {TEXT.GETSTART}
            <Divider style={{ marginBottom: "1.5rem", marginTop: "0.5rem" }} />
            <Box display="flex" flexDirection="column" alignItems="center">
              <SubmitButton
                label={"Show Welcome Wizard"}
                onClick={handleModelopen}
                style={{
                  width: "400px",
                  textTransform: "none",
                  color: "#FFFFFF",
                  backgroundColor: "#1ab394",
                  alignItems: "center",
                }}
              />
            </Box>
          </Paper>
        </Box>
        <PheonixWizardModal
          open={isModalOpen}
          onClose={handleCloseModal}
          title={"New Account Setup"}
          isEditModal={false}
          fields={[]}
          onConfirm={function (): void {
            throw new Error("Function not implemented.");
          }}
          confirmButtonText={""}
          formData={undefined}
          setFormData={function (value: unknown): void {
            throw new Error("Function not implemented.");
          }}
          onContactClick={function (contactId: string): void {
            throw new Error("Function not implemented.");
          }}
        />
      </Box>
    </Box>
  );
};

export default Settings;
