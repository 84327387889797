import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import PheonixTableProps from "../interfaces/PheonixTableProps";

const PheonixTable: React.FC<PheonixTableProps> = ({
  columns,
  data,
  cellProps,
  currentPage = 0,
  onPageChange,
  sx,
}) => {
  return (
    <TableContainer
      sx={{
        color: "#999c9e",
        marginLeft: "15px",
        maxWidth: "calc(99% - 10px)",
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                {...cellProps}
                align="left"
                style={{
                  fontSize: "14px",
                  color: "#676a6c",
                  fontWeight: "bold",
                }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) =>
            row ? (
              <TableRow key={row.id}>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    {...cellProps}
                    align="left"
                    style={{ fontSize: "13px", color: "#676a6c" }}
                  >
                    {row[column.id]}
                  </TableCell>
                ))}
              </TableRow>
            ) : null
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PheonixTable;
