import React, { useState } from "react";
import { DataGrid, GridColDef, GridRowModel } from "@mui/x-data-grid";
import PheonixDataGridProps from "../interfaces/PheonixGridProps";

interface CustomDataGridProps extends PheonixDataGridProps {
  onSelect?: (selection: any) => void;
  onEdit?: (row: GridRowModel) => void;
}

export const PheonixDataGridList: React.FC<CustomDataGridProps> = ({
  rows,
  columns,
  title,
  checkboxSelections,
  pageSizeOptions,
  onSelect,
  onEdit,
  ...props
}) => {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10 || 30 || 50,
    page: 0,
  });

  return (
    <div style={{ width: "100%", height: "100vh", display: "contents" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          paginationModel={paginationModel}
          pageSizeOptions={pageSizeOptions || [ 10, 20, 30]}
          onPaginationModelChange={setPaginationModel}
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          disableRowSelectionOnClick
          hideFooterSelectedRowCount
          sx={{
            boxShadow: 2,
            border: 2,
            overflow: "auto",
            "& .MuiDataGrid-columnHeaders": {
              color: "#428bca",
              fontSize: "13px",
            },
            "& .MuiDataGrid-cell": {
              fontSize: "13px",
              lineHeight: "18px",
            },
            "& .MuiDataGrid-iconButtonContainer": {
              display: "none",
              overflow: "auto",
            },
            "& .MuiDataGrid-sortIcon": {
              display: "none",
              overflow: "auto",
            },
            ".MuiDataGrid-columnSeparator": {
              display: "none",
              overflow: "auto",
            },
            ".MuiDataGrid-virtualScrollerContent": {
              width: "950px",
              height: "500px",
              minHeight: "auto",
              overflow: "auto",
            },
          }}
          {...props}
        />
    </div>
  );
};
