import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import PheonixSideBarProps from "../interfaces/PheonixSideBarProps";

const PheonixSideBar: React.FC<PheonixSideBarProps> = ({
  icon,
  text,
  to,
  onClick,
  isOpen,
  sx,
}) => {
  const location = useLocation();
  const isActive = location.pathname === to;
  const specialCases = ["Dashboard", "Settings", "Help", "Import Contacts"];
  const isSpecialActive = specialCases.includes(location.pathname);
  const highlightContacts =
    text === "Contacts" && text !== "Contacts" && !isSpecialActive;

  return (
    <ListItem
      sx={{
        textDecoration: "none",
        backgroundColor:
          isActive || highlightContacts ? "#293846" : "transparent",
        color: isActive || highlightContacts ? "#FFFFFF" : "#a7b1c2",
        marginTop: isOpen ? "0px" : "2%",
        position: "relative",
        "&:hover": {
          backgroundColor:
            isActive || highlightContacts ? "#293846" : "#293846",
          textDecoration: "none",
          color: "#FFFFFF",
        },
        fontSize: "13px",
        fontWeight: "600",
        width: "230px",
        fontFamily: "sans-serif",
        "& .MuiTypography-root": {
          fontSize: "13px",
          fontWeight: "600",
          fontFamily: "sans-serif",
        },
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "3px",
          backgroundColor:
            isActive || highlightContacts ? "#1ab394" : "transparent",
        },
      }}
      component={to ? Link : "div"}
      to={to}
      onClick={onClick}
      style={sx}
    >
      <ListItemIcon
        sx={{
          textDecoration: "none",
          backgroundColor: "transparent",
          color: isActive || highlightContacts ? "#FFFFFF" : "#a7b1c2",
          minWidth: "30px",
          "&:hover": {
            backgroundColor:
              isActive || highlightContacts ? "#293846" : "transparent",
            textDecoration: "none",
            color: "#FFFFFF",
          },
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "3px",
            backgroundColor: isActive ? "#1ab394" : "transparent",
          },
        }}
      >
        {icon}
      </ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  );
};

export default PheonixSideBar;
