import React, { useState, useEffect } from "react";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  Link,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { BUTTON, TEXT } from "../constant";
import { saveAs } from "file-saver";
import PheonixTable from "../Components/PheonixTable";
import Papa from "papaparse";
import { config } from "../config";
import axios from "axios";
import "./styles.css";
import { api } from "../api";
import CloseIcon from "@mui/icons-material/Close";

type OptionType = {
  name: string;
  id: string;
};

const ImportContacts: React.FC = () => {
  const [step, setStep] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [progress, setProgress] = useState(0);
  const [tableData, setTableData] = useState<any[]>([]);
  const [columns, setColumns] = useState<{ id: string; label: string }[]>([]);
  const [selectedValue, setSelectedValue] = useState("");
  const token = localStorage.getItem("authtoken");
  const [list, setLists] = useState<{ name: string; id: string }[]>([]);
  const [selectedListId, setSelectedListId] = useState<string[]>([]);
  const userId = sessionStorage.getItem("user_id");
  const [message, setMessage] = useState<string>("");
  const [ShowAlert, setShowAlert] = useState(false);
  const [listSelected, setListSelected] = useState(true);
  const [showFileInput, setShowFileInput] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string>("");
  const [finishButtonClicked, setFinishButtonClicked] = useState<boolean>(false);
  const [allContactsId, setAllContactsId] = useState("");
  const [backdropOpen, setBackdropOpen] = useState(false);

  const options = [
    { label: "" },
    { label: "Firstname" },
    { label: "Lastname" },
    { label: "Email" },
    { label: "Number" },
    { label: "Notes" },
  ];

  const fetchoption = async () => {
    try {
      const response = await axios.get(`${config.baseUrl}/v1/lists`, {
        headers: {
          "x-cotg-authtoken": token,
        },
      });

      const lists = response.data.lists.map(
        (item: { name: string; id: string }) => ({
          name: item.name,
          id: item.id,
        })
      );

      const allContactsList = lists.find((list: { name: string; }) => list.name === "All Contacts");
      var allContactsId = allContactsList ? allContactsList.id : "";
      setAllContactsId(allContactsId)
      const userIds = response.data.lists.map(
        (item: { user_id: string }) => item.user_id
      );
      const firstUserId = userIds.length > 0 ? userIds[0] : null;
      if (firstUserId) {
        sessionStorage.setItem("user_id", firstUserId);
      }
      setLists(lists);
    } catch (error) {
      console.error("Error fetching options", error);
    }
  };

  useEffect(() => {
    fetchoption();
  }, []);

  const handleImportCSV = async (uploadfile: any) => {
    if (!selectedListId) {
      console.error("No list selected");
      return;
    }

    const query = `
      mutation UploadContacts($file: Upload!, $user_id: ID!, $list_id: ID!) {
        uploadContacts(file: $file, user_id: $user_id, list_id: $list_id) {
          success
          message
        }
      }
    `;

    const listIds = selectedListId.includes(allContactsId)
    ? selectedListId
    : [...selectedListId, allContactsId];

    const listIdString = listIds.filter(Boolean).join(',');

    const variables = {
      file: null,
      user_id: userId,
      list_id: listIdString
    };

    const formData = new FormData();
    formData.append("operations", JSON.stringify({ query, variables }));
    formData.append("map", JSON.stringify({ "0": ["variables.file"] }));
    formData.append("0", uploadfile);

    try {
      setBackdropOpen(true);
      const response = await axios.post(api.baseUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-cotg-authtoken": token,
        },
      });

      if (response.data.errors) {
        console.error("GraphQL Errors", response.data.errors);
      } else {
        setTimeout(() => {
          setBackdropOpen(false);
          var { success, message } = response.data.data.uploadContacts;
        if (success) {
          setStep(0);
          setShowAlert(true);
          setMessage(TEXT.IMPORTMSG);
          setTimeout(() => {
            setShowAlert(false);
            setMessage("");
          }, 5000);
          setSelectedFile(null);
          setSelectedListId([]);
          setSelectedValue("");
          setTableData([]);  
          setTimeout(() => {
              window.location.reload();
            }, 10000);
        } else {
          setShowAlert(true);
          setErrorMessages(message);
          if(message === "The contact already exists in the list."){
            setStep(0);
            setSelectedFile(null);
            setSelectedListId([]);
            setSelectedValue("");
            setTableData([]); 
          }
          setTimeout(() => {
            setShowAlert(false);
            setErrorMessages("");
          }, 5000);
        }
      }, 10000); 
      }
   
    } catch (error) {
      console.error("Network Error:", error);
        setBackdropOpen(false);
    }
  };
  const MAX_FILE_SIZE = 3 * 1024 * 1024;
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    setFinishButtonClicked(false);
    if (file) {
      setShowAlert(false);

      if (file.size <= MAX_FILE_SIZE) {
        setSelectedFile(file);
        setUploadSuccess(false);
        setProgress(0); 

        let uploadProgress = 0;
        const interval = setInterval(() => {
          uploadProgress += 10;
          setProgress(uploadProgress);
          if (uploadProgress >= 100) {
            clearInterval(interval);
            setUploadSuccess(true);
          }
        }, 500);

        Papa.parse(file, {
          header: true,
          complete: (results: any) => {
            if (results.data.length > 0) {
              setTableData(results.data);
              const headers = Object.keys(results.data[0]);
              const newColumns = headers.map((header) => ({
                id: header,
                label: header.charAt(0).toUpperCase() + header.slice(1),
              }));
              setColumns(newColumns);
              setShowAlert(false); 
            } else {
              setShowAlert(true); 
              setErrorMessages(TEXT.NOFILE);
              setTimeout(() => {
                setShowAlert(false);
                setErrorMessages("");
              }, 5000);
            }
          },
          error: (error: any) => {
            setShowAlert(true); 
            setErrorMessages(TEXT.FILEERROR);
          },
        });
        setShowFileInput(true);
      } else {
        setShowAlert(true); 
        setErrorMessages(TEXT.FILELARGE);
        setSelectedFile(null);
        setProgress(0);
      }
    } else {
      setSelectedFile(null);
      setProgress(0);
    }
  };

  const handleChooseFile = () => {
    document.getElementById("file-upload")?.click();
  };

  const handleDownloadCSV = () => {
    const header = ["first_name", "last_name", "number", "email", "notes"];
    const data = [header];

    const csvContent = data.map((row) => row.join(",")).join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    saveAs(blob, "cotg_template.csv");
  };

  const handleNext = () => {
    if (step === 0) {
      if (!selectedListId.length) {
        setListSelected(false);
        return;
      } else {
        setListSelected(true);
      }
    }

    if (step === 1 && !selectedFile) {
      setUploadSuccess(false);
      return;
    }

    if (step === 2 && selectedFile ) {
      setFinishButtonClicked(true);
      handleImportCSV(selectedFile);
      setFinishButtonClicked(false);
    } else {
      setFinishButtonClicked(true);
      setFinishButtonClicked(false);
      setStep((prevStep) => prevStep + 1);
      setShowFileInput(true);
      setFinishButtonClicked(false);
    }
  };

  const handlePrevious = () => {
    setStep((prevStep) => (prevStep > 0 ? prevStep - 1 : 0));
  };

  const handleSteppage = (newStep: number) => {
    setStep(newStep);
  };

  return (
    <div style={{ color: "#f3f3f4", backgroundColor: "#F3F3F4" }}>
      {message && ShowAlert && (
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={() => setShowAlert(true)}
            >
              <CloseIcon sx={{ fontSize: "small", color: "#676a6c" }} />
            </IconButton>
          }
          style={{ color: "#3c763d" }}
        >
          {message}
        </Alert>
      )}
      {errorMessages && ShowAlert &&(
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={() => setShowAlert(false)}
            >
              <CloseIcon sx={{ fontSize: "small", color: "#676a6c" }} />
            </IconButton>
          }
          style={{ color: "#A94342", background: "#F2DEDD" }}
        >
          {errorMessages}
        </Alert>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div style={{ width: "70%" }}>
          <Typography
            variant="h3"
            style={{
              marginTop: "1%",
              color: "#2f4050",
              fontSize: "16px",
              padding: "2px 2px 4px 0px",
              margin: "0px 0px 0px 25px",
            }}
          >
            {TEXT.Submit}
          </Typography>
          <Typography
            variant="h6"
            style={{
              color: "#2f4050",
              padding: "2px 2px 10px 0px",
              fontSize: "13px",
              margin: "0px 0px 0px 25px",
            }}
          >
            {TEXT.ImportContacts}
          </Typography>
          <Box
            sx={{
              padding: "10px",
              margin: "10px 0px 0px 20px",
              display: "flex",
              gap: "20px",
            }}
          >
            <Button
              variant="contained"
              size="small"
              onClick={() => handleSteppage(0)}
              sx={{
                color: "#ffffff",
                width: "100%",
                textTransform: "none",
                lineHeight: "1.428571429",
                justifyContent: "left",
                backgroundColor:
                  step === 0
                    ? listSelected
                      ? "#1ab394"
                      : "#ED5564"
                    : "#6ED1BD",
                padding: "8px 8px 8px 8px",
                "&:hover": {
                  backgroundColor: listSelected ? "#1ab394" : "#ED5564",
                },
              }}
            >
              {BUTTON.CHOOSE_LIST}
            </Button>
            <Button
              variant="contained"
              size="small"
              disabled={
                !((selectedListId.length && step === 1) || selectedFile)
              }
              onClick={() => handleSteppage(1)}
              sx={{
                color: "#ffffff",
                width: "100%",
                textTransform: "none",
                lineHeight: "1.428571429",
                justifyContent: "left",
                backgroundColor: selectedFile
                  ? step === 1
                    ? "#1ab394"
                    : "#6ED1BD"
                  : "#ED5564",
                padding: "8px 10px 8px 16px",
                "&:hover": {
                  backgroundColor: selectedFile ? "#1ab394" : "#ED5564",
                },
              }}
            >
              {BUTTON.UPDATE_FILE}
            </Button>

            <Button
              variant="contained"
              size="small"
              onClick={() => handleSteppage(2)}
              disabled={!(selectedFile || step === 2) }
              sx={{
                color: "#ffffff",
                width: "100%",
                textTransform: "none",
                lineHeight: "1.428571429",
                justifyContent: "left",
                backgroundColor: step === 2 ? "#1ab394" : "#6ED1BD",
                padding: "8px 10px 8px 16px",
                "&:hover": {
                  backgroundColor: "#1ab394",
                },
              }}
            >
              {BUTTON.PREVIEW}
            </Button>
          </Box>
        </div>
        <Paper
          elevation={0}
          style={{
            padding: "10px",
            margin: "10px 0px 0px 30px",
            width: "95%",
            height: step === 2 ? "400px" : "200px",
            color: "#999c9e",
            backgroundColor: "#EEEEEE",
          }}
        >
          {step === 0 && (
            <>
              <Typography
                variant="h6"
                style={{
                  color: "#2f4050",
                  fontSize: "13px",
                  padding: "20px 0px 0px 30px",
                }}
              >
                {TEXT.CONTENT}
              </Typography>
              <Box sx={{ padding: "20px 10px 0px 30px" }}>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  id="combo-box-demo"
                  options={list}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      const ids = newValue.map((option) => option.id);
                      setSelectedListId(ids);
                      setSelectedValue(
                        newValue.map((option) => option.name).join(", ")
                      );
                    } else {
                      setSelectedListId([]);
                      setSelectedValue("");
                    }
                  }}
                  value={
                    selectedListId
                      .map((id) => list.find((option) => option.id === id))
                      .filter((option) => option) as OptionType[]
                  }
                  renderTags={(value: OptionType[], getTagProps) =>
                    value.map((option: OptionType, index: number) => (
                      <Chip
                        label={option.name}
                        {...getTagProps({ index })}
                        size="small"
                        sx={{
                          background: "#23c6c8",
                          color: "#FFFFFF",
                          borderRadius: "2.5px",
                          "& .MuiChip-deleteIcon": {
                            color: "#FFFFFF",
                            "&:hover": {
                              color: "#FFFFFF",
                            },
                          },
                        }}
                      />
                    ))
                  }
                  sx={{ width: 600, fontSize: "10px" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      style={{
                        height: "30px",
                        fontSize: "10px",
                        fontWeight: "100",
                        width: "600px",
                      }}
                      InputProps={{
                        ...params.InputProps,
                        style: { fontSize: "10px" },
                      }}
                    />
                  )}
                />
              </Box>
            </>
          )}
          {step === 1 && (
            <>
              <Typography
                variant="h6"
                style={{
                  color: "#2f4050",
                  fontSize: "13px",
                  padding: "20px 0px 0px 30px",
                }}
              >
                {TEXT.UPLOAD_CONTENT}
                <br />
                {TEXT.UPLOAD_CONTENT_1}
              </Typography>
              <form style={{ margin: "30px" }}>
                <div className="input_container">
                  {showFileInput && (
                    <>
                      <label
                        className="custom-file-upload"
                        onClick={handleChooseFile}
                      >
                        Choose file
                      </label>
                      {selectedFile ? selectedFile.name : "No file choosen"}
                      <input
                        type="file"
                        id="file-upload"
                        accept=".csv"
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                      />
                    </>
                  )}
                </div>
              </form>
            </>
          )}
          {step === 2 && (
            <>
              <Typography
                variant="h6"
                style={{
                  color: "#2f4050",
                  fontSize: "13px",
                  padding: "20px 0px 0px 30px",
                }}
              >
                {TEXT.PREVTEW_CONTENT}
              </Typography>
              <div
                style={{
                  height: "300px",
                  margin: "10px 0px 0px 0px",
                  overflowY: "auto",
                }}
              >
                <PheonixTable columns={columns} data={tableData} />
              </div>
            </>
          )}
        </Paper>
        <Box
          style={{
            padding: "10px",
            margin: "0px 0px 0px 30px",
            display: "flex",
            gap: "10px",
          }}
        >
          <Button
            variant="contained"
            size="small"
            onClick={handlePrevious}
            disabled={step === 0}
            sx={{
              color: "#ffffff",
              backgroundColor: "#1ab394",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#1ab394",
              },
            }}
          >
            {BUTTON.PERVIOUS}
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={handleNext}
            disabled={step === 2 && tableData.length === 0}
            sx={{
              color: "#ffffff",
              backgroundColor: "#1ab394",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#1ab394",
              },
            }}
          >
            {step === 2 ? "Finish" : BUTTON.NEXT}
          </Button>
        </Box>
        <Box sx={{ padding: "0px 10px 0px 10px " }}>
          <Typography
            variant="h6"
            style={{
              color: "#2f4050",
              fontSize: "16px",
              padding: "30px 0px 0px 30px",
              fontWeight: "bold",
            }}
          >
            {TEXT.TEMPLATE_HERE}{" "}
            <Link
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleDownloadCSV();
              }}
              sx={{ color: "#428bca", textDecoration: "none" }}
            >
              here
            </Link>
          </Typography>
          <Box sx={{ padding: "8px 0px 0px 30px " }}>
            <iframe
              width="265%"
              height="800"
              src="https://www.youtube-nocookie.com/embed/LQcY9E0lFNo?si=mApUrAGY1TgBLS4D&amp;start=1"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </Box>
        </Box>
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropOpen}
      >
       <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <CircularProgress color="inherit" />
          <Typography  sx={{ mt: 2, color: "#FFFFFF" }}>
            {TEXT.FILE_UPLOAD}
          </Typography>
        </Box>
      </Backdrop>
      </div>
    </div>
  );
};

export default ImportContacts;
