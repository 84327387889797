import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Grid,
  TextareaAutosize,
  IconButton,
  Button,
  Checkbox,
  Divider,
  Typography,
  Chip,
  Alert,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CloseIcon from "@mui/icons-material/Close";
import PheonixGroupModalProps from "../interfaces/PheonixModel";
import axios from "axios";
import { config } from "../config";

interface OptionType {
  id: string;
  name: string;
}

const PheonixContactModal = <T,>({
  open,
  onClose,
  title,
  fields,
  onConfirm,
  confirmButtonText,
  formData,
  setFormData,
  isEditModal,
}: PheonixGroupModalProps<T>) => {
  const typedFormData = formData as Record<string, string>;
  const token = localStorage.getItem("authtoken");
  const [options, setOptions] = useState<OptionType[]>([]);
  const [selectedContacts, setSelectedContacts] = useState<OptionType[]>([]);
  const [errorMessages, setErrorMessages] = useState<Record<string, string>>(
    {}
  );
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [iserror, setIsErrorValid] = useState(true);

  const handleCloseModal = () => {
    if (onClose) {
      setSelectedContacts([]);
      handleFieldChange("List", "", []);
      onClose();
    }
    setSelectedContacts([]);
    handleFieldChange("List", "", []);
    setIsPhoneValid(false);
    fetchoption();
  };
  const handleContactSelection = (event: any, value: OptionType[]) => {
    setSelectedContacts(value);
    handleFieldChange("List", "", value);
  };
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const handleFieldChange = (
    label: string,
    value: string,
    value1: OptionType[] = []
  ) => {
    let errorMessage = "";

    if (label === "Phone") {
      if (!value) {
        errorMessage = "Phone number is required.";
        setIsPhoneValid(false);
      } else {
        setIsPhoneValid(true);
      }
    }

    if (label === "Email") {
      if (!value) {
        setIsErrorValid(false);
        errorMessage = "Email is required.";
        setIsEmailValid(false);
      } else if (!emailRegex.test(value)) {
        setIsErrorValid(false);
        errorMessage = "Please enter a valid email address.";
        setIsEmailValid(false);
      } else {
        setIsErrorValid(true);
        setIsEmailValid(true);
      }
    }
    if (setFormData) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [label]: value,
        List: value1.map((option) => option.id),
      }));
    }

    setErrorMessages((prev) => ({
      ...prev,
      [label]: errorMessage,
    }));
  };

  const fetchoption = async () => {
    try {
      const response = await axios.get(config.baseUrl + "/v1/lists", {
        headers: {
          "x-cotg-authtoken": token,
        },
      });

      const id: OptionType[] = response.data.lists.map(
        (item: { id: string; name: string }) => ({
          id: item.id,
          name: item.name,
        })
      );
      setOptions(id);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };

  useEffect(() => {
    fetchoption();
  }, [token]);

  const handleConfirmContact = () => {
    if (onConfirm) {
      onConfirm();
    }
    setSelectedContacts([]);
    handleFieldChange("List", "", []);
    fetchoption();
    handleCloseModal();
    setIsPhoneValid(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseModal}
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: "white",
            width: "70%",
            height: "auto",
            padding: "3px 30px",
            boxShadow: "0px 4px 4px 0px #00000040",
            border: "1px solid #0000001F",
          },
        }}
      >
        <DialogTitle
          sx={{
            fontSize: "24px",
            fontWeight: "bold",
            height: "80px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0px",
            marginLeft: "0px",
            marginTop: "0px",
          }}
        >
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 10,
            top: 0,
            color: "#999c9e",
          }}
        >
          <CloseIcon sx={{ color: "#999c9e" }} />
        </IconButton>
        <DialogContent>
          <DialogContentText style={{ marginTop: "16px" }}>
            <Grid container spacing={3}>
              {fields.map((field) => (
                <Grid item xs={12} key={field.label}>
                  {field.type === "textarea" ? (
                    <TextareaAutosize
                      aria-label={field.label}
                      placeholder={field.label}
                      minRows={5}
                      style={{ width: "100%" }}
                      value={typedFormData[field.label]}
                      onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                        handleFieldChange(field.label, e.target.value)
                      }
                    />
                  ) : field.type === "autocomplete" ? (
                    <Autocomplete
                      multiple
                      id={field.label.toLowerCase().replace(/\s/g, "-")}
                      options={options}
                      getOptionLabel={(option) => option.name}
                      disableCloseOnSelect
                      onChange={handleContactSelection}
                      value={selectedContacts}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </li>
                      )}
                      renderTags={(value: OptionType[], getTagProps) =>
                        value.map((option: OptionType, index: number) => (
                          <Chip
                            variant="filled"
                            label={option.name}
                            {...getTagProps({ index })}
                            sx={{
                              background: "#F1F1F1",
                              color: "#676a6c",
                              fontSize: "10px",
                              borderRadius: "0.9px",
                            }}
                          />
                        ))
                      }
                      style={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={field.label}
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      label={field.label}
                      variant="outlined"
                      value={typedFormData[field.label]}
                      onChange={(e) =>
                        handleFieldChange(field.label, e.target.value)
                      }
                      error={!!errorMessages[field.label]}
                      helperText={errorMessages[field.label]}
                      size="small"
                      style={{ width: "100%", marginBottom: "0px" }}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseModal}
            sx={{
              fontWeight: "bold",
              width: "15%",
              height: "42px",
              marginBottom: "25px",
              backgroundColor: "#D32F2F",
              color: "white",
              marginRight: "5%",
              textTransform: "none",
              marginTop: "1%",
              "&:hover": {
                backgroundColor: "#D32F2F",
                color: "white",
              },
              "&:focus": {
                backgroundColor: "#D32F2F",
                color: "white",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmContact}
            color="primary"
            sx={{
              fontWeight: "bold",
              width: "15%",
              textTransform: "none",
              height: "42px",
              marginBottom: "25px",
              backgroundColor:
                !isPhoneValid || !isEmailValid ? "#E0E0E0" : "#1ab394",
              color: "white",
              marginRight: "5%",
              marginTop: "1%",
              "&:hover": {
                backgroundColor:
                  !isPhoneValid || !isEmailValid ? "#E0E0E0" : "#1ab394",
                color: "white",
              },
              "&:focus": {
                backgroundColor:
                  !isPhoneValid || !isEmailValid ? "#E0E0E0" : "#1ab394",
                color: "white",
              },
            }}
            disabled={!isPhoneValid || !isEmailValid}
          >
            {confirmButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PheonixContactModal;
