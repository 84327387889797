import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Button,
  Grid,
  Typography,
  Divider,
  Paper,
  Box,
  Autocomplete,
} from "@mui/material";
import PheonixModalProps from "../interfaces/PheonixModel";
import CloseIcon from "@mui/icons-material/Close";
import { BUTTON, TEXT, URL } from "../constant";
import googlestore from "../assets/google_PlayStore_logo.png";
import appstore from "../assets/IOS_logo.png";
import Papa from "papaparse";
import PheonixTable from "./PheonixTable";
import "../pages/styles.css";

const PheonixWizardModal = <T,>({
  open,
  onClose,
  title,
  fields,
  onConfirm,
  confirmButtonText,
  formData,
  setFormData,
  isEditModal,
}: PheonixModalProps<T>) => {
  const [step, setStep] = useState(0);
  const handleSteppage = (newStep: number) => {
    setStep(newStep);
  };
  const [selectedFile, setSelectedFile] = useState<{ file: File | null }>({
    file: null,
  });
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isFirstRender, setIsFirstRender] = useState(false);
  const handleChooseFile = () => {
    document.getElementById("file-upload")?.click();
  };
  const [tableData, setTableData] = useState<any[]>([]);
  const [columns, setColumns] = useState<{ id: string; label: string }[]>([]);

  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  const options = [
    { label: "   " },
    { label: "Firstname" },
    { label: "Lastname" },
    { label: "Email" },
    { label: "Number" },
    { label: "Notes" },
  ];
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    const maxSize = 3 * 1024 * 1024;
    if (file && file.size <= maxSize) {
      setSelectedFile({ file });
      setUploadSuccess(false);
      let uploadProgress = 0;
      const interval = setInterval(() => {
        uploadProgress += 10;
        setProgress(uploadProgress);
        if (uploadProgress >= 100) {
          clearInterval(interval);
          setUploadSuccess(true);
        }
      }, 500);

      Papa.parse(file, {
        header: true,
        complete: (results: any) => {
          setTableData(results.data);
          if (results.data.length > 0) {
            //@ts-ignore
            const headers = Object.keys(results.data[0]);
            const newColumns = headers.map((header) => ({
              id: header,
              label: header.charAt(0).toUpperCase() + header.slice(1),
            }));
            setColumns(newColumns);
          }
        },
        error: (error: any) => {
          console.error("Error parsing CSV file:", error);
        },
      });
    } else {
      setSelectedFile({ file: null });
      setProgress(0);
    }
  };

  const handleNext = () => {
    setStep((prevStep) => (prevStep === 2 ? 0 : prevStep + 1));
  };

  const handlePrevious = () => {
    setStep((prevStep) => (prevStep > 0 ? prevStep - 1 : 0));
  };

  return (
    <>
      <div style={{ color: "#f3f3f4", backgroundColor: "#F3F3F4" }}>
        <Dialog
          open={open}
          onClose={onClose}
          sx={{
            "& .MuiDialog-paper": {
              backgroundColor: "white",
              height: "400px",
              width: "90%",
              padding: "100px 100px 0px 60px",
              boxShadow: "0px 4px 4px 0px #00000040",
              margin: "1px 150px 200px 0px ",
            },
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <DialogTitle
              sx={{
                fontSize: "20px",
                alignItems: "center",
                color: "#999c9e",
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                margin: "-60px 0px 0px 180px",
                padding: "0px 0px 0px 50px ",
              }}
            >
              {title}
            </DialogTitle>
            <Typography
              sx={{
                fontSize: "12px",
                color: "#999c9e",
                justifyContent: "center",
                textAlign: "center",
                padding: "0px 0px 0px 50px ",
              }}
            >
              {TEXT.SUB_TITLE}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: "absolute",
                right: 2,
                top: 20,
                color: "#999c9e",
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <CloseIcon sx={{ fontSize: "16px", color: "#999c9e" }} />
            </IconButton>
          </div>
          <Divider
            style={{
              marginBottom: "1.5rem",
              marginTop: "1rem",
              marginLeft: "-80px",
              marginRight: "-100px",
            }}
          />
          <div
            style={{ color: "red", display: "flex", flexDirection: "column" }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "10px",
              }}
            >
              <Button
                variant="contained"
                size="small"
                onClick={() => handleSteppage(0)}
                sx={{
                  color: "#ffffff",
                  width: "40%",
                  textTransform: "none",
                  lineHeight: "1.428571429",
                  justifyContent: "left",
                  backgroundColor: step === 0 ? "#1ab394" : "#6ED1BD",
                  padding: "8px 8px 8px 8px",
                  "&:hover": {
                    backgroundColor: "#1ab394",
                  },
                }}
              >
                {BUTTON.IMPORT_BUTTON}
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={() => handleSteppage(1)}
                disabled={isFirstRender}
                sx={{
                  color: "#ffffff",
                  width: "40%",
                  textTransform: "none",
                  lineHeight: "1.428571429",
                  justifyContent: "left",
                  backgroundColor: step === 1 ? "#1ab394" : "#6ED1BD",
                  padding: "8px 10px 8px 16px",
                  "&:hover": {
                    backgroundColor: "#1ab394",
                  },
                }}
              >
                {BUTTON.APPS}
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={() => handleSteppage(2)}
                disabled={isFirstRender}
                sx={{
                  color: "#ffffff",
                  width: "40%",
                  textTransform: "none",
                  lineHeight: "1.428571429",
                  justifyContent: "left",
                  backgroundColor: step === 2 ? "#1ab394" : "#6ED1BD",
                  padding: "8px 10px 8px 16px",
                  "&:hover": {
                    backgroundColor: "#1ab394",
                  },
                }}
              >
                {BUTTON.GET_STARTED}
              </Button>
            </Box>
            <Paper
              elevation={0}
              style={{
                padding: "10px",
                margin: "20px 0px 0px 0px",
                width: "105%",
                height: "208px",
                color: "#999c9e",
                backgroundColor: "#EEEEEE",
                overflowY: "auto",
              }}
            >
              {step === 0 && (
                <>
                  <Typography
                    variant="h6"
                    style={{
                      color: " #676a6c",
                      fontSize: "16px",
                      padding: "20px 0px 0px 30px",
                      fontWeight: "600",
                    }}
                  >
                    {TEXT.IMPORT_CONTACT}
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{
                      color: "#676a6c",
                      fontSize: "13px",
                      padding: "20px 0px 0px 30px",
                    }}
                  >
                    {TEXT.CSV_CONTENT_1}
                    <br />
                    {TEXT.CSV_CONTENT_2}
                    <br />
                    {TEXT.CSV_CONTENT_3}
                    <br />
                  </Typography>
                  {!selectedFile.file && (
                    <form style={{ margin: "30px" }}>
                      <div className="input_containers">
                        <input
                          type="file"
                          id="file-upload"
                          onChange={handleFileChange}
                        />
                      </div>
                    </form>
                  )}
                  {selectedFile.file && (
                    <Typography
                      variant="h6"
                      style={{
                        color: " #676a6c",
                        fontSize: "16px",
                        padding: "20px 0px 0px 30px",
                        fontWeight: "600",
                      }}
                    >
                      {TEXT.PREVIEW}
                    </Typography>
                  )}
                  {selectedFile.file && (
                    <Typography
                      variant="h6"
                      style={{
                        color: "#676a6c",
                        fontSize: "13px",
                        padding: "5px 0px 0px 30px",
                      }}
                    >
                      {TEXT.PREVIEW_CONTENT}
                    </Typography>
                  )}
                  <div style={{ fontSize: "10px" }}>
                    {selectedFile.file && (
                      <PheonixTable columns={columns} data={tableData} />
                    )}
                    {selectedFile.file && (
                      <Button
                        variant="contained"
                        size="small"
                        onClick={handleChooseFile}
                        sx={{
                          color: "#ffffff",
                          backgroundColor: "#1ab394",
                          textTransform: "none",
                          margin: "20px 0px 0px 20px ",
                          "&:hover": {
                            backgroundColor: "#1ab394",
                          },
                        }}
                      >
                        {BUTTON.UPLOAD}
                      </Button>
                    )}
                    <a
                      href={URL.MAILID}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        variant="h6"
                        style={{
                          color: "#2A6496",
                          fontSize: "13px",
                          padding: "20px 0px 0px 20px",
                          textDecoration: "none",
                          cursor: "pointer",
                        }}
                      >
                        {TEXT.HAVING_TROUBLE}
                      </Typography>
                    </a>
                  </div>
                </>
              )}
              {step === 1 && (
                <>
                  <Typography
                    variant="h6"
                    style={{
                      color: "#676A6C",
                      fontSize: "16px",
                      fontWeight: "600",
                      padding: "10px 0px 0px 10px",
                    }}
                  >
                    {TEXT.DOWNLOAD_APP}
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{
                      color: "#676A6C",
                      fontSize: "13px",
                      padding: "5px 0px 0px 10px",
                    }}
                  >
                    {TEXT.LINKING_APP}
                  </Typography>
                  <a
                    href={URL.GOOGLE_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={googlestore}
                      alt="googlestore"
                      style={{
                        margin: "10px 0px 0px 10px",
                        width: "140px",
                        height: "45px",
                      }}
                    />
                  </a>
                  <a
                    href={URL.APP_STORE_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={appstore}
                      alt="appstore"
                      style={{
                        marginLeft: "10px",
                        width: "140px",
                        height: "45px",
                      }}
                    />
                  </a>
                </>
              )}
              {step === 2 && (
                <>
                  <Typography
                    variant="h6"
                    style={{
                      color: "#676a6c",
                      fontSize: "16px",
                      padding: "10px 0px 0px 10px",
                      fontWeight: "600",
                    }}
                  >
                    {TEXT.START_CALL}
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{
                      color: "#676a6c",
                      fontSize: "13px",
                      padding: "10px 0px 0px 10px",
                    }}
                  >
                    {TEXT.INFO_DETAILS}
                  </Typography>
                </>
              )}
            </Paper>

            <Box
              style={{
                padding: "10px",
                margin: "0px 0px 0px 0px",
                display: "flex",
                gap: "10px",
              }}
            >
              <Button
                variant="contained"
                size="small"
                onClick={handlePrevious}
                disabled={step === 0}
                sx={{
                  color: "#ffffff",
                  backgroundColor: "#1ab394",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#1ab394",
                  },
                }}
              >
                {BUTTON.PERVIOUS}
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={handleNext}
                sx={{
                  color: "#ffffff",
                  backgroundColor: "#1ab394",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#1ab394",
                  },
                }}
              >
                {step === 2 ? "Finish" : BUTTON.NEXT}
              </Button>
            </Box>
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default PheonixWizardModal;
