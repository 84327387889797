import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PheonixMoveModalProps from "../interfaces/PheonixMoveModel";

const PheonixMoveModelModal: React.FC<PheonixMoveModalProps> = ({
  open,
  onClose,
  onConfirm,
  onMove,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor: "white",
          width: "auto",
          boxShadow: "0px 4px 4px 0px #00000040",
          border: "1px solid #0000001F",
          padding: "10px 30px 10px 10px",
          margin: "0px 10px 600px 100px",
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 0,
          top: 5,
          color: "#999c9e",
        }}
      >
        <CloseIcon sx={{ color: "#999c9e" }} />
      </IconButton>
      <DialogActions>
        <Button
          onClick={onMove}
          sx={{
            textTransform: "none",
            borderRadius: "4px",
            padding: "8px 22px",
            width: "84px",
            height: "36px",
            backgroundColor: "#1ab394",
            color: "white",
            "&:hover": {
              backgroundColor: "#1ab394",
              color: "white",
            },
            "&:focus": {
              backgroundColor: "#1ab394",
              color: "white",
            },
          }}
        >
          Move
        </Button>
        <Button
          onClick={onConfirm}
          sx={{
            textTransform: "none",
            color: "white",
            fontSize: "14px",
            padding: "8px 22px",
            width: "84px",
            height: "36px",
            backgroundColor: "#1ab394",
            "&:hover": {
              backgroundColor: "#1ab394",
              color: "white",
            },
          }}
        >
          Copy
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PheonixMoveModelModal;
