import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import SignUp from "./pages/signup";
import Dashboard from "./pages/dashboard";
import Contacts from "./pages/contacts";
import Referral from "./pages/Referral";
import Settings from "./pages/Settings";
import ImportContacts from "./pages/importcontact";
import AllContacts from "./pages/allcontacts";
import Layout from "./pages/Layout";
import Forgotpwd from "./pages/forgotpwd";
import Resetpwd from "./pages/resetpwd";
import Help from "./pages/help";
import List from "./pages/list";
import { SessionContextProvider, useSession } from "./context/sessioncontext";
import { useEffect, useState } from "react";
import Search from "./pages/Search";

const NavigateToLogin = () => {
  const { isLoggedIn } = useSession();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const loggedIn = sessionStorage.getItem("isLoggedIn") === "true";
    if (!loggedIn && location.pathname !== "/users/sign_in") {
      navigate("/users/sign_in", { replace: true });
    }
  }, [isLoggedIn, location.pathname, navigate]);

  return null;
};

function App() {
  const [dataFromAllContacts, setDataFromAllContacts] = useState<string>("");

  const handleDataFromAllContacts = (data: string) => {
    setDataFromAllContacts(data);
  };

  return (
    <Router>
      <SessionContextProvider>
        <NavigateToLogin />
        <Routes>
          <Route path="/users/sign_in" element={<SignUp />} />
          <Route path="/users/password/new" element={<Forgotpwd />} />
          <Route path="/users/password/edit" element={<Resetpwd />} />
          <Route
            path="/"
            element={
              <Layout title="">
                <Dashboard />
              </Layout>
            }
          />
          <Route
            path="/lists/:listIds"
            element={
              <Layout dataFromAllContacts={dataFromAllContacts}>
                {" "}
                <AllContacts onDataSend={handleDataFromAllContacts} />
              </Layout>
            }
          />
          <Route
            path="/referral"
            element={
              <Layout>
                <Referral />
              </Layout>
            }
          />
          <Route
            path="/users/edit"
            element={
              <Layout>
                <Settings />
              </Layout>
            }
          />
          <Route
            path="/imports/new"
            element={
              <Layout>
                <ImportContacts />
              </Layout>
            }
          />
          <Route
            path="/help"
            element={
              <Layout>
                <Help />
              </Layout>
            }
          />
          <Route
            path="/search"
            element={
              <Layout>
                <Search />
              </Layout>
            }
          />
          <Route
            path="/list"
            element={
              <Layout>
                <List />
              </Layout>
            }
          />
        </Routes>
      </SessionContextProvider>
    </Router>
  );
}

export default App;
