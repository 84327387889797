import React, { ReactNode, CSSProperties } from 'react';
import Container from '@mui/material/Container';
import { PheonixContainerProps } from '../interfaces/PheonixContainerProps';
const PheonixContainer: React.FC<PheonixContainerProps> = ({ children, style,maxWidth }) => {
    return (
      <Container
        maxWidth="xs"
        style={{
          display: 'flex',
          flexDirection:'column'
        }}
      >
        {children}
      </Container>
    );
  };
  export default PheonixContainer;
  