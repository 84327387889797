import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PheonixTextField from "../Components/PheonixTextField";
import { BUTTON, TEXT, URL } from "../constant";

const Referral: React.FC = () => {
  const [inputValue, setInputValue] = useState("");

  const handleshare = () => {};

  const handleFacebookClick = () => {
    window.open(URL.FB_URL, "_blank");
  };

  const handleXClick = () => {
    window.open(URL.TWITTER_URL, "_blank");
  };

  const handleLinkedinClick = () => {
    window.open(URL.LINKEDIN_URL, "_blank");
  };

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        backgroundColor: "#f3f3f4",
        width: "100%",
        height: "100vh",
      }}
    >
      <Divider style={{ marginBottom: "1.5rem", marginTop: "0.5rem" }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          marginLeft: "30px",
          marginTop: "6%",
        }}
      >
        <Paper
          elevation={0}
          style={{
            display: "flex-start",
            padding: "30px 10px 0px 0px",
            width: "25%",
            height: "300px",
            color: "#999c9e",
            backgroundColor: "#ffffff",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "30px",
              textAlign: "center",
              fontWeight: "bold",
              color: "#676a6c",
            }}
          >
            Share the Love
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              textAlign: "center",
              color: "#676a6c",
            }}
          >
            Give <span style={{ color: "#ff0000" }}>1 month</span> of
            CallOnTheGo Free
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
            }}
          >
            <IconButton onClick={handleFacebookClick}>
              <FacebookIcon sx={{ color: "#316FF6", fontSize: "40px" }} />
            </IconButton>
            <IconButton onClick={handleXClick}>
              <TwitterIcon sx={{ color: "#1DA1F2", fontSize: "40px" }} />
            </IconButton>
            <IconButton onClick={handleLinkedinClick}>
              <LinkedInIcon sx={{ color: "#0077b5", fontSize: "40px" }} />
            </IconButton>
          </Box>
          <Box
            sx={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <PheonixTextField
              variant="outlined"
              style={{ height: "30px", width: "280px" }}
              type="text"
              placeholder="Enter Emails...(Commas separate)"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </Box>
          <Box
            sx={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              padding: "10px",
            }}
          >
            <Button
              variant="contained"
              size="small"
              onClick={handleshare}
              sx={{
                color: "#ffffff",
                textTransform: "none",
                width: "80%",
                backgroundColor: "#1ab394",
                "&:hover": {
                  backgroundColor: "#1ab394",
                },
              }}
            >
              {TEXT.SHARE}
            </Button>
          </Box>
          <Box
            sx={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              padding: "10px",
            }}
          >
            <Typography
              style={{ color: "#CCCCCC", fontSize: "18px", cursor: "pointer" }}
            >
              <a
                href={TEXT.PREVIEW_LINK}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#CCCCCC", textDecoration: "none" }}
              >
                {BUTTON.SEE_PERVIEW}
              </a>
            </Typography>
          </Box>
        </Paper>
        <Box
          sx={{ display: "flex", flexDirection: "column", marginLeft: "20px" }}
        >
          <Typography variant="h6" sx={{ color: "#676a6c", fontSize: "14px" }}>
            {TEXT.REFERRAL}
          </Typography>
          <Typography sx={{ textDecoration: "none", fontSize: "14px" }}>
            <a
              href={TEXT.REF_LINK}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#428BCA", textDecoration: "none" }}
            >
              {TEXT.REF_LINK}
            </a>
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default Referral;
