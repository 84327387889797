import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AppBarProps from "../interfaces/AppBarProps";
import PheonixSearch from "./PheonixSearch";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { Typography, IconButton, Box } from "@mui/material";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { TEXT } from "../constant";
import { useSession } from "../context/sessioncontext";

const AppBar: React.FC<AppBarProps> = ({ onToggle, isSidebarOpen }) => {
  const [email, setEmail] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const { logout } = useSession();
  const location = useLocation();

  const iconComponent = (
    <Box
      border={1}
      borderRadius={3}
      p={0}
      style={{ color: "#1ab394", backgroundColor: "#1ab394" }}
    >
      <IconButton style={{ color: "#FFFFFF" }} onClick={onToggle}>
        {isSidebarOpen ? (
          <ArrowBackOutlinedIcon />
        ) : (
          <ArrowForwardOutlinedIcon />
        )}
      </IconButton>
    </Box>
  );

  const titleMarginLeft = isSidebarOpen ? "230px" : "80px";
  const navigate = useNavigate();
  useEffect(() => {
    const storedEmail = sessionStorage.getItem("email");
    setEmail(storedEmail || "Guest");
  }, []);
  const handleLogout = () => {
    logout();
    navigate("/users/sign_in");
    sessionStorage.removeItem("email");
    sessionStorage.setItem("message", TEXT.LOGOUTMSG);
  };
  const handlesettings = () => {
    navigate("/users/edit");
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const query = params.get("searchQuery") || "";
    setSearchQuery(query);
  }, [location.search]);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    navigate(`/search?searchQuery=${query}`);
  };

  return (
    <div
      style={{
        backgroundColor: "#f3f3f4",
        height: "48px",
        padding: "10px",
        borderBottom: "1px solid #ccc",
        width: "98%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        position: "relative",
        zIndex: 1,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flex: 1,
          marginLeft: titleMarginLeft,
        }}
      >
        {iconComponent}
        <PheonixSearch
          onSearch={handleSearch}
          onChange={(query) => setSearchQuery(query)}
        />
      </div>
      <div
        style={{ display: "flex", alignItems: "center", marginRight: "40px" }}
      >
        <Typography
          variant="body1"
          style={{ color: "#999c9e", marginRight: "20px", cursor: "pointer" }}
          onClick={handlesettings}
        >
          {email}
        </Typography>
        <PowerSettingsNewIcon
          style={{ color: "#999c9e", cursor: "pointer", fontSize: "18px" }}
          onClick={handleLogout}
        />
        <button
          style={{
            textTransform: "none",
            color: "#999c9e",
            fontFamily: "sans-serif",
            fontSize: "14px",
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
          }}
          onClick={handleLogout}
        >
          {TEXT.LOGOUT}
        </button>
      </div>
    </div>
  );
};

export default AppBar;
