import React, { useState, ReactNode } from "react";
import Sidebar from "./sidebar";
import PheonixAppBar from "../Components/PhenoixAppBar";

interface LayoutProps {
  title?: string;
  children: ReactNode;
  dataFromAllContacts?: string;
}
const Layout: React.FC<LayoutProps> = ({ children, dataFromAllContacts }) => {
  const [isOpen, setIsOpen] = useState(true);
  const handleToggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div style={{ backgroundColor: "#f3f3f4", width: "100%" }}>
      <PheonixAppBar isSidebarOpen={isOpen} onToggle={handleToggleSidebar} />
      <div style={{ display: "flex" }}>
        <Sidebar
          isOpen={isOpen}
          onToggle={handleToggleSidebar}
          dataFromAllContacts={dataFromAllContacts}
        />
        <div
          style={{
            flexGrow: 1,
            backgroundColor: "#f3f3f4",
            overflow: "hidden",
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
